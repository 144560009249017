import { AdDefinition, AdUnitMode, Slot, DataObject } from '@repo/shared-types';

const findBestAdForSlot =
  (slot: DataObject<Slot>) =>
  (bestAdDefinition: AdDefinition | null, adDefinition: AdDefinition): AdDefinition | null => {
    if (slot.getProperty('adID') !== undefined) {
      return bestAdDefinition;
    }

    if (adDefinition.mode !== AdUnitMode.SLOTIFY) {
      return bestAdDefinition;
    }

    const slotAdCategoryAllowList = slot.getProperty('adCategoryAllowList');
    const adCategory = adDefinition.category;
    if (
      slotAdCategoryAllowList.length !== 0 &&
      (adCategory === undefined || !slotAdCategoryAllowList.includes(adCategory))
    ) {
      return bestAdDefinition;
    }

    if (slot.getProperty('fluid') && adDefinition.fluid) {
      return bestAdDefinition || adDefinition;
    }

    const adWidth = adDefinition.width;
    const adHeight = adDefinition.height;
    const slotWidth = slot.getProperty('width');
    const slotHeight = slot.getProperty('height');
    if (
      adWidth > slotWidth ||
      adHeight > slotHeight ||
      (bestAdDefinition !== null && (bestAdDefinition.width || 1) >= adWidth)
    ) {
      return bestAdDefinition;
    }

    return adDefinition;
  };
export default findBestAdForSlot;
